.more-container {
  margin-top: 60px;
  margin-bottom: 42px;
  padding: 0 45px;

  @media only screen and (max-width: 700px) {
    padding: 0 15px;
  }

  h3 {
    color: #1b1c57;
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 32px;
    @media only screen and (max-width: 700px) {
      font-size: 20px !important;
    }
  
    @media only screen and (max-width: 475px) {
      font-size: 16px !important;
    }
  }

  .swiper-title {
    color: #351B57;
    text-align: center;
    font-family: "IBM Plex Sans Arabic";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    @media only screen and (max-width: 700px) {
        font-size: 16px !important;
      }
    
      @media only screen and (max-width: 475px) {
        font-size: 12px !important;
      }
  }

  img {
    width: 100%;
    height: 150px;
    border-radius: 4px;
    cursor: grab;
    object-fit: cover;

    @media only screen and (max-width: 700px) {
      height: 75px;
      border-radius: 2.75px;
    }
  }

  .more-swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
  }

  .btn {
    cursor: pointer;
    background: #C4C4C433;
    padding: 1px 9px;
    border-radius: 50%;
  }
}
