.swiper-slide-width {
    width: 100% !important;
}

.more-swiper {
    justify-content: space-between !important;
}

.swiper-container-adjustments {
    margin: 0;
}