@use "../abstracts/variables" as *;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-style: normal;
  // overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  // background: #ffffff;
  background: #F5F5F5;
}
a {
  color: inherit;
  text-decoration: none;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-width: 4px;
  scrollbar-color: #dadada #f4f4f4;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  z-index: 120;
}

*::-webkit-scrollbar-track {
  background: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 0;
  border: 0px solid #f4f4f4;
}

.light {
  .text {
    color: $dark;
  }
  .background {
    background-color: $white;
  }
}
.dark {
  .text {
    color: $white;
  }
  .background {
    background-color: $dark;
  }
}


.floating_whatsapp-btn-en {
  direction: rtl;
  position: absolute;
  right: 3rem;
  bottom: 4rem;
  z-index: 100;

  @media only screen and (max-width: 720px) {
    right: 2rem;
    bottom: 2rem;
  }

  img {
    height: 4rem;
    width: 4rem;

    @media only screen and (max-width: 720px) {
      height: 3rem;
      width: 3rem;
    }
  }
}

.floating_whatsapp-btn-ar {
  direction: ltr;
  position: absolute;
  left: 3rem;
  bottom: 4rem;
  z-index: 100;

  @media only screen and (max-width: 720px) {
    right: 2rem;
    bottom: 2rem;
  }

  img {
    height: 4rem;
    width: 4rem;

    @media only screen and (max-width: 720px) {
      height: 3rem;
      width: 3rem;
    }
  }
}