.more-container {
  margin-top: 60px;
  margin-bottom: 42px;
  padding: 0 45px;

  @media only screen and (max-width: 700px) {
    padding: 0 15px;
  }

  h3 {
    color: #1b1c57;
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 32px;
  }

  img {
    width: 100%;
    height: 150px;
    border-radius: 12px;
    cursor: grab;
    object-fit: cover;

    @media only screen and (max-width: 700px) {
      height: 75px;
    }
  }

  .more-swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
  }

  .btn {
    cursor: pointer;
    background: #C4C4C433;
    padding: 1px 9px;
    border-radius: 50%;
  }
}
