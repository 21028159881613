.contact-container {
  margin-top: 60px;
  margin-bottom: 42px;
  padding: 0 200px;

  @media (max-width: 1190px) {
    padding: 0 100px;
  }

  @media (max-width: 768px) {
    padding: 0 50px;
  }
  @media (max-width: 475px) {
    padding: 0 25px;
  }

  .contact-container-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 60.66px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 50.55px;
      margin-bottom: 24px;
    }

    @media (max-width: 475px) {
      font-size: 24px;
      line-height: 40.44px;
      margin-bottom: 24px;
    }
  }
  
}

.contact-info-contents {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 24px;
  margin-bottom: 42px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .info-content-card {
    padding: 24px 24px 32px 24px;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    background: #F4EFFF;
    backdrop-filter: blur(15px);
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .info-title-area {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3{
        color: #351B57;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
    .description-area {
      p {
        color:  #351B57;
        font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
      }
    }
  }
    .info-content-card.bg_blue {
      background: #330561;

      h3 {
        color: #FFF;
      }

      .description-area {
        p {
          color: #fff;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
}


.logo {
  width: 10rem;
  padding: 1.5rem;
}

.contact-form-contents {
  .contact-title {
    color: #260E35;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 168.5%;
    /* 53.92px */
    text-transform: capitalize;
  }
  .contact-paragraph {
    color: #260E35;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 637.587px;
  }
}

.form-group {
  @media (max-width: 1100px) {
    width: 100%;

    & > input,
    textarea {
      width: 100%;
    }
  }
}

form {
  width: 100%;
  position: relative;
  margin-top: 1rem;
  padding: 1rem 0;
}

.contact-input,
.contact-textarea,
.contact-input-label {
  width: 100%;
  display: block;
  @media (max-width: 700px) {
    width: max-content;
  }
}

p,
placeholder,
.contact-input,
.contact-textarea {
  font-family: "IBM Plex Sans Arabic", sans-serif;;
}

input::placeholder,
.contact-textarea::placeholder {
  color: #010712;
}

.contact-input,
.contact-textarea {
  color: #010712;
  font-weight: 500;
  background: #fcfdfd;
  border: none;
  border-bottom: 1px solid #818386;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  outline: none;
}

.contact-textarea {
  resize: none;
}

.contact-submit-button {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 38px;
  background: #1b1c57;
  border-radius: 12px;
  padding: 8px 16px;
}

input:hover,
.contact-textarea:hover,
.contact-submit-button:hover {
  opacity: 0.5;
}

.contact-submit-button:active {
  opacity: 0.8;
}
.description {
  margin-left: 1.25rem;
}

input:checked + .slider {
  background-color: #3b3636;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

#error,
#success-msg {
  width: 100%;
  margin: 0.125rem 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  color: #818386;
}

#success-msg {
  transition-delay: 3s;
}

@media only screen and (max-width: 950px) {
  .logo {
    width: 8rem;
  }
  .contact-title {
    font-size: 1.75rem;
  }
  p {
    // font-size: 0.7rem;
  }
  .contact-input,
  .contact-textarea,
  .contact-submit-button {
    font-size: 0.65rem;
  }
  .description {
    font-size: 0.3rem;
    margin-left: 0.4rem;
  }
  .contact-submit-button {
    width: 7rem;
  }
  .theme-switch-wrapper {
    width: 120px;
  }
  .theme-switch {
    height: 28px;
    width: 50px;
  }

  .theme-switch input {
    display: none;
  }

  .slider:before {
    background-color: #fff;
    bottom: 0.25rem;
    content: "";
    width: 20px;
    height: 20px;
    left: 0.25rem;
    position: absolute;
    transition: 0.4s;
  }
  input:checked + .slider:before {
    transform: translateX(16px);
  }
}

.is-invalid {
  border-bottom: 1px solid red !important;
  padding-inline: 10px;
}

.invalid-feedback {
  color: red; // Change this to the desired error text color
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  margin-top: -.5rem;
}
