.header {
  display: flex;
  padding: 12px 170px;
  width: 100%;
  z-index: 6;
  // height: 100px;
  // border: 0.5px solid #e0e0e0;
  justify-content: space-between;
  align-items: center;
  height: 73px;
  background: #F8F5FF !important;
  backdrop-filter: blur(15px) !important;
  @media only screen and (max-width: 1589px) {
    padding: 12px 70px;
  }
  @media only screen and (max-width: 1091px) {
    padding: 12px 30px;
    background-color: #F8F5FF !important;
    color: #626687;
  }
  @media only screen and (max-width: 700px) {
    padding: 12px 20px;
  }
  & > .header__group {
    display: flex;
    gap: 18px;
    align-items: center;
    // text-align: right;
    cursor: pointer;
    user-select: none;
    @media only screen and (max-width: 1089px) {
      flex-grow: 1;
      justify-content: space-between;
    }
    .search_header {
      width: 320px;
    }
  }
  .header__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    @media only screen and (max-width: 1089px) {
      flex-grow: 1;
      justify-content: space-between;
    }
    .item_header {
      color: #626687;
      font-family: "IBM Plex Sans Arabic";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      /* 200% */
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        color: #260E35;
      }
    }

    .sign_up_header {
      cursor: pointer;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #ffffff;
      height: 38px;
      background: #ffffff;
      border-radius: 12px;
      padding: 8px 16px;
    }
    .login_header {
      cursor: pointer;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #ffffff;
      height: 38px;
      background: #1b1c57;
      border-radius: 12px;
      padding: 8px 16px;
    }

        .header-dropdown {
          float: left;
          overflow: hidden;
        
          .header-dropbtn {
            font-weight: 700;
            font-size: 16px;
            line-height: 32px;
            text-transform: capitalize;
            color: #1b1c57;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            outline: none;
            background-color: transparent;

            img{
              width: 16px;
              height: 16px;
              margin-left: 3px;
              margin-right: 3px;

            }
          }

          &:hover {
            .header-dropbtn {
              color: #260E35;
            }

            .header-dropdown-content {
              display: block;
            }
            .header-dropdown-content_ar {
              display: block;
            }
          }

          .header-dropdown-content {
            display: none;
            position: absolute;
            min-width: 160px;
            max-width: 350px;
            right: 5%;
            width: 100%;
            border-radius: 12px;
            background: #F8F5FF;
            background-color: #F8F5FF;            
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
            z-index: 1;
            padding: 1rem;
          }
          .header-dropdown-content_ar {
            display: none;
            position: absolute;
            min-width: 160px;
            max-width: 350px;
            left: 5%;
            width: 100%;
            border-radius: 12px;
            background: #F8F5FF;
            background-color: #F8F5FF;            
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
            z-index: 1;
            padding: 1rem;
          }

          .header-dropdown-content a {
            float: none;
            color: black;
            padding: 12px 0px;
            text-decoration: none;
            display: block;
            text-align: left;
          }
          .header-dropdown-content_ar a {
            float: none;
            color: black;
            padding: 12px 0px;
            text-decoration: none;
            display: block;
            text-align: right;
          }
        }
  }
  .navigation_link {
    color: black !important;
  }

  .exit_btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header_responsive {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    direction: ltr !important;
    .left_responsive {
      display: flex;
      gap: 0px;

    }
    .header-mobile-logo {
      height: 50px;
      width: auto;
    }
  }
}
