.landing-footer {
  margin: 32px 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  background: #eaf4ee;
  border-radius: 36px;
    border: 1.125px solid var(--black, #000);
    background: #E4D9FF;

  @media only screen and (max-width: 700px) {
    margin: 32px 20px;
    margin: 20px;
    height: 258px !important;
    border-radius: 12px;
  }

  .landing-footer-container {
    padding: 32px;

    .title {
      color: #1b1c57;
      text-align: center;
      font-size: 36px;

      font-weight: 700;
      line-height: 54px;
      text-transform: capitalize;

      @media only screen and (max-width: 700px) {
        color: var(--mrt-app-headline, #1b1c57);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 54px;
        /* 450% */
        text-transform: capitalize;
      }
    }

    .subtitle {
      color: #1b1c57;
      text-align: center;
      font-size: 27px;

      text-transform: capitalize;

      @media only screen and (max-width: 700px) {
        color: var(--mrt-app-headline, #1b1c57);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
    }

    @media only screen and (max-width: 768px) {
      .title {
        font-size: 26px;
      }

      .subtitle {
        font-size: 17px;
      }
    }

    .input-container {
      margin-top: 22px;
      border-radius: 13.5px;
      background: #fff;
      display: flex;
      padding: 9px 18px;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      width: 100%;

      @media only screen and (max-width: 700px) {
        padding: 4.5px 9px;
        border-radius: 6.75px;

        input::placeholder {
          font-size: 7px;
        }
      }

      input {
        border: 0;
        width: 100%;
        outline: none !important;
      }

      .register-btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: #1b1c57;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        color: #ffffff;
        background: #6304C2;
        border-radius: 12px;
        padding: 8px 16px;

        @media only screen and (max-width: 700px) {
          color: #fff;
          text-align: right;
          font-size: 9px;
          font-style: normal;
          font-weight: 700;
          line-height: 13.5px;
          /* 150% */
          border-radius: 6.75px;
          height: 100%;

          img {
            width: 10px;
          }
        }
      }
    }
  }
}