.more-container{
    margin-top: 60px;
    margin-bottom: 42px;
    padding: 0 45px;

    @media only screen and (max-width: 768px) {
            padding: 0 25px;
        }
    @media only screen and (max-width: 475px) {
            padding: 0 15px;
        }

    h3 {
        color: #1b1c57;
        text-align: center;
        font-size: 27px;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 32px;
    }

    .content-list {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 24px;
        @media only screen and (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .content-card {
            padding: 24px 24px 32px 24px ;
            border-radius: 16px;
            border: 1px solid var(--Gray-5, #E0E0E0);
            background: #F4EFFF;
            backdrop-filter: blur(15px);

            .inner-contents {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 24px;

                .vector {
                    display: flex;
                    width: 54px;
                    height: 54px;
                    padding: 10px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    border: 1px solid #E0E0E0;
                    background:  #FFF;

                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 0px
                    }
                }

                p {
                    color: #351B57;
                    font-family: "IBM Plex Sans Arabic", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    @media only screen and (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }
        }

        .content-card.blue-bg {
            background: #330561;

            .inner-contents {
                    p {
                        color: white;
                    }
            }
        }
    }
}