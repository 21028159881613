.navigation__container {
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: flex-start;
  width: 100%;

  .navigation__container-title {
    font-size: 20px;
    color: #330561;
    line-height: 30px;
    margin-bottom: 15px;
    padding: 0px 36px;
  }
  & > .navigation_link {
    display: flex;
    align-items: center;
    gap: 14px;
    color: #626687;
    padding: 0px 36px;
    & > svg {
      stroke: #626687;
    }

    span {
      color: var(--white, #626687);
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .item_lan_header {
    color: #626687;
     img {
     color: #626687;
     filter: unset;
    }
  }

    .header-dropdown {
      float: left;
      overflow: hidden;
      width: 100%;
  
      .header-dropbtn {
        -webkit-tap-highlight-color: transparent;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        text-transform: capitalize;
        color: #1b1c57;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;
        width: 100%;
        padding: 0px 36px;
  
        img {
          width: 16px;
          height: 16px;
          margin-left: 3px;
          margin-right: 3px;
  
        }
      }

      .header-dropdown-content_open {
        display: block;
      }

      .header-dropdown-content_ar_open {
        display: block;
      }

      .header-dropdown-hidden {
        display: none;
      }
  
      &:hover {
        .header-dropbtn {
          color: #260E35;
        }
  
        .header-dropdown-content {
          display: block;
        }
  
        .header-dropdown-content_ar {
          display: block;
        }
      }
  
      .header-dropdown-content {
        display: none;
        position: relative;
        right: 0%;
        width: 100%;
        border-radius: 12px;
        z-index: 1;
        padding: 12px 0px 0px 0px;
      }
  
      .header-dropdown-content_ar {
        display: none;
        position: relative;
        left: 0%;
        width: 100%;
        border-radius: 12px;
        z-index: 1;
        padding: 12px 0px 0px 0px;
      }
  
      .header-dropdown-content a {
        float: none;
        color: black;
        padding: 12px 36px;
        text-decoration: none;
        display: block;
        text-align: left;
      }
  
      .header-dropdown-content_ar a {
        float: none;
        color: black;
        padding: 12px 36px;
        text-decoration: none;
        display: block;
        text-align: right;
      }
    }

  .submit_prop {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    background: var(--basic, #04c23a);
    color: var(--white, #fff);

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .login_sidebar {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--white, #fff);

    color: var(--dark-blue, #1b1c57);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .logout_sidebar {
    display: flex;
    padding: 9px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--red, #ff1d1d);
    border: none;
    cursor: pointer;
    color: var(--white, #fff);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
}


.navigation_link.active {
  color: #1E1E1E;
}