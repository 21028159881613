.landing {
  .content-container {
    // background-image: url('../../assets/icons/landing/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    object-fit: cover;
    width: 100%;
    min-height: 80vh;
    padding: 45px;

    @media only screen and (max-width: 768px) {
      min-height: 50vh;
    }

    .content-card {
      // overflow: hidden;
      min-width: 320px;
      max-width: 100%;
      //  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      position: absolute;
      top: 20%;
      left: 45px;
      border-radius: 1rem;
      border: 1px solid var(--white-main, #FFF);
      background: rgba(234, 244, 238, 0.12);
      backdrop-filter: blur(15px);

      @media only screen and (max-width: 768px) {
        min-width: unset;
        top: 8%;
        left: 6%;
        right: 6%;
      }

      .content-title {
        color: #330561;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #DECBE9;
        -webkit-text-stroke: 1px #DECBE9;
        font-family: "IBM Plex Sans Arabic", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        padding: 24px;

        @media only screen and (max-width: 475px) {
          color: #330561;
            -webkit-text-stroke-width: 1;
            -webkit-text-stroke-color: #DECBE9;
            font-family: "IBM Plex Sans Arabic";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }
      }

      .button-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 24px;
        @media only screen and (max-width: 768px) {
          display: none;
        }
        button {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 12px 24px;
          cursor: pointer;
          background-color: transparent;
          border: none;
          color: #351B57;
          font-family: "IBM Plex Sans Arabic", sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          border-bottom: 1px solid #E0E0E0;
        }

        button:hover {
          background-color: #FFFFFF;
          color: #351B57;
        }
      }

      .button-list button:last-child {
        border-bottom: none;
        // border-bottom-left-radius: 1rem;
        // border-bottom-right-radius: 1rem;
      }
    }
   
    .content-card-ar {
      width: fit-content;
      right: 45px;

      @media only screen and (max-width: 768px) {
        min-width: unset;
        top: 8%;
        left: 6%;
        right: 6%;
      }
    }
    .menu-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 50px;
      right: 50px;
    }

    position: relative;

    .vr-toor-iframe {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // z-index: -1;
      object-fit: cover;

      @media only screen and (max-width: 768px) {
          position: absolute !important;
          top: 20px;
          left: 50%;
          width: 92%;
          height: 100%;
          object-fit: cover;
          transform: translateX(-50%);
          border-radius: 8px;
        }
    }
  }

  @media only screen and (max-width: 485px) {
    .content-container {
      .content-card {
        display: block;
      }

      .menu-icon {
        display: block;
        float: right;
      }
    }
  }
}

.sale-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .sale-price-container {
    .sale-price-title {
      color: #626687;
      font-size: 12px;

      font-weight: 700;
      line-height: 16.5px;
      margin-bottom: 6px;
    }

    .sale-price-values-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .MuiSlider-rail {
      height: 9px;
    }

    .MuiSlider-track {
      color: #1b1c57 !important;
      height: 9px;
    }

    .MuiSlider-thumb {
      color: #1b1c57 !important;
      width: 28px;
      height: 28px;
    }

    .slider-values-container {
      span {
        margin-top: 5px;
        color: var(--light-dark, #626687);
        text-align: right;
        font-family: "IBM Plex Sans Arabic", sans-serif;
        font-size: 13.654px;
        font-style: normal;
        font-weight: 400;
        line-height: 27.308px;
      }
    }

    .sale-price-title {
      margin-bottom: 10px;
    }
  }

  .search-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .search-btn {
      color: #fff;
      text-align: right;
      font-size: 12px;

      font-weight: 700;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      border: none;
      color: #ffffff;
      background: #04c23a;
      border-radius: 9px;
      padding: 8px 16px;
      width: 86px;
    }
  }

  .sale-select-container {
    padding: 2px 6px 2px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    border: 0.75px solid #1b1c57;
    background: #eaf4ee;

    .ant-select {
      .ant-select-selector {
        outline: none;
        background: transparent;
        border: 0 !important;
        box-shadow: none !important;
      }
    }
  }

  .sale-date-container {
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    background: #eaf4ee;
    border: 0.75px solid #1b1c57;

    .ant-picker {
      outline: none;
      background: transparent;
      border: 0 !important;
      box-shadow: none !important;
      width: 100%;
    }
  }

  @media only screen and (max-width: 485px) {
    .sale-select-container {
      background-color: white;
    }
  }
}

.rent-container {
  .ant-radio-wrapper {
    span {
      color: #626687;
      font-size: 12px;
      font-weight: 700;
      line-height: 16.5px;
    }
  }

  .ant-radio-wrapper {
    .ant-radio {
      display: none;
    }

    .checkbox-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .checkbox-checked {
        display: none;
      }

      .checkbox-not-checked {
        display: flex;
      }
    }
  }

  .ant-radio-wrapper-checked {
    .checkbox-content {
      .checkbox-checked {
        display: flex;
      }

      .checkbox-not-checked {
        display: none;
      }
    }
  }
}
