.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 105px 0px;
  gap: 60px;
  justify-content: space-between;
  z-index: 6;
  overflow: scroll;
  overflow-x: hidden;
  overflow: auto;
  width: 100%;
  // min-width: 393px;
  background: #F8F5FF;
  // border-radius: 8px;
  overflow: hidden;
  @media only screen and (max-width: 1089px) {
    position: absolute;
    top: 0;
    left: -100%;
    // background: rgba(59, 65, 68, 0.8);
  }
  &.open {
    @media only screen and (max-width: 1089px) {
      left: 0;
      overflow: auto;
    }
  }
  &.open_ar {
    right: -393px;
    overflow: auto;
    @media only screen and (max-width: 1089px) {
      right: 0;
    }
  }
  & > .sidebar__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    .divider {
      background: #E0E0E0;
      width: 100%;
      height: 1px;
    }
  }
}

.sidebar_ar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 105px 0px;
  z-index: 6;
  gap: 60px;
  justify-content: space-between;
  z-index: 10;
  overflow: scroll;
  overflow-x: hidden;
  overflow: auto;
  width: 100%;
  // min-width: 393px;
  background: #F8F5FF;
  // border-radius: 8px;
  @media only screen and (max-width: 1089px) {
    position: absolute;
    top: 0;
    right: -100%;
    // background: rgba(59, 65, 68, 0.8);
  }
  &.open_ar {
    z-index: 6;

    @media only screen and (max-width: 1089px) {
      right: 0;
    }
  }
  & > .sidebar__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .divider {
      background: #E0E0E0;
      width: 100%;
      height: 1px;
    }
  }
}
