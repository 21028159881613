.spinner_p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  height: 50vh;
}
.loader {
  display: inline-block;
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  //   color: red;
  position: relative;
  color: #330561;
}
.loader::before {
  content: "";
  position: absolute;
  left: 32px;
  // bottom: 8px;
  bottom: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-bottom-color: #330561;
  box-sizing: border-box;
  animation: rotation 0.6s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
