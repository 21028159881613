.text_input_component {
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px;
  }
  //
  .input_antd {
    height: 46px;
    // background: #ffffff;
    // background: #ffffff;
    border: 0.75px solid rgba(196, 196, 196, 0.5);
    border-radius: 9px;
    .ant-input-prefix {
      margin-inline-end: 8px;
    }

    input {
      &::placeholder {
        font-family: "IBM Plex Sans Arabic", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #626687;
      }
    }
  }
  .ant-select-show-search {
    z-index: 10;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    border: none;
    & > div {
      height: 44px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
    }
  }
  .ant-select-dropdown.css-dev-only-do-not-override-ixblex.ant-select-dropdown-empty.ant-select-dropdown-placement-topLeft {
    z-index: 10 !important;
  }
}

.error_txt {
  color: red;
  font-size: 11px;
  // margin-top: -10px;
  margin-bottom: -6px;
}
.gray_input {
  background-color: #c4c4c4cc;
  input {
    background: transparent;
    // background-color: inherit;
  }
}
